import React from "react";
import { Container } from "react-bootstrap";
import styles from "./Securelogiq.module.css";
import "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from 'react-bootstrap/Carousel';

const securelogiqPage = () => {
    return (
        <>
            <div class={styles.carouselBackground}>
                    <Carousel className={styles.MainCarousel_Margins + " d-none d-lg-block"}>
                        <Carousel.Item >
                            <img
                                className="d-block w-100 Image_Size"
                                src={process.env.PUBLIC_URL + "/Images/ProductImages/Norbain_Banner.jpg"}
                                alt="First slide"
                            />
                        </Carousel.Item>
                    </Carousel>
                </div>
            <Container>
                <Row className={styles.rowContent}>
                    <Col>
                        <p>
                            Security Warehouse is the distribution partner for Secure Logiq in India.   
                        </p>
                        <p>
                            Secure Logiq, headquartered in the UK,  are the global leaders 
                            in Specialised Server Technology for HD surveillance.
                        </p>
                        <p>
                            Secure Logiq Servers provide for the Fastest Performance, the Biggest Storage 
                            and the Most Resilience in HD processing and storage technology. 
                            This means the Best Value available in the Industry!   
                        </p>
                        <p>
                            The ability to efficiently store, transmit and display multiple streams of 
                            HD video data is critical in Surveillance and this becomes even more important when 
                            large number of HD Cameras are involved. Secure Logiq servers provide for the optimum performance 
                            year after year irrespective of the size of the installation. 
                        </p>
                        <p>
                            With an industry leading Design department providing detailed calculations, system design and technical drawings 
                            Secure Logiq offer a design guarantee when involved in a project from the start, and it's a free service!!
                        </p>
                        <p>
                            Secure Logiq servers are VMS agnostic and are custom built to provide the optimum hardware solution for every single 
                            project.
                        </p>
                        <p>
                            With a Research &amp; Development centre based in Croydon, UK, Secure Logiq also provide a unique range of professional services 
                            and custom solutions to the HD CCTV surveillance and security sector including Specific Servers for running Video Analytics, 
                            Access Control Servers, Workstations,  Video Walls Solutions and many other bespoke solutions. 
                        </p>
                        <p>
                            All Secure Logiq servers come equipped with a unique Logical Health Check feature which gives an overall dashboard view of the server condition 
                            including the health status every process and component in the hardware and raising critical alerts for in the event of any abnormal events or component failures.
                        </p>
                        <p>
                            Secure Logiq Enterprise servers carry a 5 year Next Business day onsite warranty globally.
                        </p>
                        <p>
                            To know more about Secure Logiq contact <a href="mailto:sales@securitywarehouse.co.in">sales@securitywarehouse.co.in</a>
                        </p>
                        <p>
                            More information on Secure Logiq can be seen <a href="https://securelogiq.com/products">here</a>.
                        </p>
                    </Col>
                </Row>
                <Row className={styles.rowContent2}>
                    <Col md={4}>
                        <img src={process.env.PUBLIC_URL + "/Images/ProductImages/Design_Guarantee.png"} className={styles.imageClass2}/>
                    </Col>
                    <Col md={4}>
                        <img src={process.env.PUBLIC_URL + "/Images/ProductImages/Enterprise_Partner.png"} className={styles.imageClass2}/>
                    </Col>
                    <Col md={4}>
                        <img src={process.env.PUBLIC_URL + "/Images/ProductImages/Healthcheck_Pro_Installed.png"} className={styles.imageClass}/>
                    </Col>
                </Row>
            </Container>
        </>
    );

}

export default securelogiqPage;