import React, {Fragment} from 'react';
import "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from 'react-bootstrap';
import styles from "./OurCustomers.module.css";

const ourCustomers = () => {
    return (
        <>
            <Container>
                <Row className={styles.rowContent}>
                    <Col>
                        <p>Here is a small list of clients who have worked with our Partner System Integrators and availed the benefit of solutions from Security Warehouse</p>
                        <ul className={styles.ulMargins}>
                            <li>Mumbai City Surveillance</li>
                            <li>Hyderabad City Surveillance</li>
                            <li>Hyderabad Police</li>
                            <li>Nagpur City Surveillance</li>
                            <li>Reliance Industries Limted – Mumbai &amp; Jamnagar</li>
                            <li>Reliance Communications</li>
                            <li>INS Kalinga, Vizag</li>
                            <li>CDK, Chennai</li>
                            <li>CDK, Pune</li>
                            <li>Barclays Bank, Mumbai</li>
                            <li>Barclays bank, Noida</li>
                            <li>Great West Global, Pune</li>
                            <li>Mahagenco</li>
                            <li>Kerala Prisons</li>
                            <li>GIA</li>

                        </ul>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ourCustomers;