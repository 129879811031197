import React, {Fragment} from "react";
import styles from "./AboutUs.module.css";
import "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";

const aboutUs = () => {
    return (
        <>
            <Container>
                <Row className={styles.rowContent}>
                    <Col>
                        <p>Wouldn’t it be nice if we did not need security?</p>
                        <p>As the world of commerce becomes more competitive, business will seek out opportunities. 
                            As social environments become more volatile, the need for security grows. 
                            More Buildings, more people, varying cultures all increase the need for comprehensive security.</p>
                        <p>
                        The simple fact is that security systems work for you. It definitely works better than the security guard at your doorstep.
                        </p>
                        <p>
                        Security Warehouse brings to you the very best of technologies be it CCTV, Bio-Metric Readers, Access Control or Transmission and Switching Equipment. 
                        Solutions designed bearing in mind the latest technologies available, to the long term value that it will provide to our customers by implementing such technologies.
                        </p>
                        <p>
                        Be it Industrial or Corporate, Transportation or Stadiums we have a solution which will provides years of reliable, scalable and superior performance in terms of scope or service.
                        </p>
                        <p>
                        Security Warehouse provides world class security solutions backed 
                        by a team of experts having over 35 years of experience individually and over 100 years of cumulative experience in the Electronic Security Sector.
                        </p>
                        <p>
                        Security Warehouse provides value added training and consulting services to its clients as a one stop solution provider for all Security based applications.
                        </p>
                        <p>
                        Avail the benefit of Technology and Experience!
                        </p>
                        <p>
                        Contact us at <a href="mailto:sales@securitywarehouse.co.in">sales@securitywarehouse.co.in</a>  to know more
                        </p>
                    </Col>
                </Row>
                <Row className={styles.rowContent}>
                    <Col>
                        <h3>
                            Mission Statement
                        </h3>
                        <ul className={styles.ulMargins}>
                            <li className={styles.AboutUs_Mission}>Security Warehouse strives to carve a niche in its chosen field of Distribution of Technology based Security Solutions.</li>
                            <li className={styles.AboutUs_Mission}>
                            It aspires to enhance the customer’s environment by offering solutions for making his world a safer, secure , efficient place to live in by giving long term TCO Value!
                            </li>
                            <li className={styles.AboutUs_Mission}> 	Introduce Innovative Technology Solutions to the Indian Market {"&"} Security Industry!</li>
                        </ul>
                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default aboutUs;