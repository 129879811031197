import React from "react";
import { NavLink, BrowserRouter } from "react-router-dom";
// import Navbar from "react-bootstrap/Navbar";
// import Container from "react-bootstrap/Container";
import "react-bootstrap";
import styles from "./Navbar.module.css";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const navbar = () => {
    return (
      <BrowserRouter>
      <Navbar bg="light" expand="lg" variant="light" sticky="top">
              <Navbar.Brand href="/">
                <img src={process.env.PUBLIC_URL + "/Images/Logos/Logo_SWH.jpg"} className={styles.navbarBrand}/>
              </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-controls"/>
          <Navbar.Collapse id="basic-navbar-controls">
            <Nav className="me-auto">
              <NavDropdown title="About Us" id="aboutus-dropdown" className={styles.navbarCollapse}>
                <NavDropdown.Item href="/aboutUs">
                    About Us
                </NavDropdown.Item>
                <NavDropdown.Item href="/ourCustomers">
                    Our Customers
                </NavDropdown.Item>
                <NavDropdown.Item href="/news">
                    News
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Products" id="products-dropdown" className={styles.navbarCollapse}>
                <NavDropdown.Item href="/avigilon">
                    Avigilon
                </NavDropdown.Item>
                <NavDropdown.Item href="/natalus">
                    Natalus
                </NavDropdown.Item>
                <NavDropdown.Item href="/qumulex">
                    Qumulex
                </NavDropdown.Item>
                <NavDropdown.Item href="/raytec">
                    Raytec
                </NavDropdown.Item>
                <NavDropdown.Item href="/vanderbilt">
                    Vanderbilt
                </NavDropdown.Item>
                <NavDropdown.Item href="/eyelock">
                    Eyelock
                </NavDropdown.Item>
                <NavDropdown.Item href="/securelogiq">
                    Secure Logiq
                </NavDropdown.Item>
                <NavDropdown.Item href="/alcatrazai">
                    Alcatraz AI
                </NavDropdown.Item>
                <NavDropdown.Item href="/sinpro">
                    SINPRO
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link className={styles.navbarCollapse} href="/services">
                  Services
              </Nav.Link>
              <Nav.Link className={styles.navbarCollapse} href="/contactUs">
                  Contact Us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
      </Navbar>
    </BrowserRouter>
    );
}

export default navbar;