import React, {Fragment} from 'react';
import "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from 'react-bootstrap';
import styles from "./Avigilon.module.css";

const avigilonPage = () => {
    return (
        <>
            <Container>
                <Row className={styles.rowContent}>
                    <Col>
                        <p>
                        Security Warehouse is the oldest partner of Avigilon in India.
                        </p> 
                        <p>
                        Security Warehouse understood the many significant advantages that Avigilon brings to CCTV and partnered 
                        Avigilon fully convinced that HD surveillance was future way back in 2010 when India had not yet realised the importance of High Definition Surveillance.
                        </p>
                        <p>
                        As Security Warehouse educated people about the need for 
                        High Definition Surveillance, gradually business houses and corporates started switching to High Definition Surveillance equipment. 
                        </p>
                        <p>
                        Avigilon is the undisputed World Leader in High Definition Surveillance manufacturing both Hardware and Software.
                        </p>
                        <p>
                        The Avigilon Range of Cameras consist of cameras with resolutions from 1.3MP to 61MP thus providing a solution for every application.
                        </p>
                        <p>
                        The Patented Software using HDSM technology allows users to view multiple High Definition streams on any client machine.
                        </p>
                        <p>
                        Avigilon also provides a range of AI powered Video Analytics at the Edge including Face Recognition.
                        </p>
                        <p>
                        Avigilon also provides an enterprise class Access Control System with full integration to Avigilon Video Management Solution.
                        </p>
                        <p>
                        If you need to know more about Avigilon contact <a href="mailto:sales@securitywarehouse.co.in">sales@securitywarehouse.co.in</a>
                        </p>
                        <p>
                        Avigilon product details are available at <a href="https://www.avigilon.com/">Focus on What Matters Most » Avigilon</a>
                        </p>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default avigilonPage;