import React, {Fragment} from "react";
import "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from 'react-bootstrap';
import styles from "./OurServices.module.css";

const ourServices = () => {
    return(
        <>
            <Container>
                <Row className={styles.rowContent}>
                    <Col>
                        <p>
                        Security Warehouse provides value added training and consulting services to its clients as a one stop solution provider for all Security based applications.
                        </p>
                        <p>
                        Here is a small list of services given below
                        </p>
                        <ul className={styles.ulMargins}>
                            <li>Product Training for its range of products to end users and system integrating partners.</li>
                            <li>Site Visits for Risk Assessment &amp; Design Consideration</li>
                            <li>Creating a recommendation documentation for Client</li>
                            <li>Drafting of Performance Specification &amp; BOQ </li>
                            <li>Short list Vendors for Bidding post Due Diligence Process</li>
                            <li>Strategy &amp; Design for Security Systems, Lighting &amp; Control</li>
                            <li>Create a Tender Document “RFQ”.</li>
                            <li>Evaluations of Tender based on RFQ and provide detailed analysis.</li>
                            <li>Project Management</li>
                            <li>Submit variations and compliances to the Client based on Tender RFQ</li>
                            <li>System Trials / POC and commencement of the installation</li>
                            <li>Audit Installations &amp; Provide Consultancy on Upgrades</li>
                            <li>Create and Audit Training program for Client</li>
                            <li>Audit System for Gaps based on Initial Recommendations &amp; Variations</li>
                            <li>Provide Manpower and Staffing personnel trained for Security Operations</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ourServices;