import React from 'react';
import styles from "./HomePage.module.css";
import "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Container } from 'react-bootstrap';


const homePage = () => {
    return (
            <>  
            <Container>
                <Row className={styles.rowContent}>
                    <Col >
                        <p >
                            Welcome to Security Warehouse India Pvt Ltd.
                        </p>
                        <p>
                            From 2010 Security Warehouse India Pvt Ltd has been in the 
                            forefront of providing leading Global Technologies to System Integrators in India. 
                        </p>
                        <p>
                            Security Warehouse provides to its clients a range of high performance products which include CCTV, Access Control,
                            IR illuminators, Communication &amp; Transmission Products and various accessories which include ATEX Certified 
                            Ex-proof CCTV Camera housings and CCTV test Equipments.
                        </p>
                        <p>
                            Security Warehouse constantly endeavours to keep up with global trends and continuously updates its technology and representations. 
                            Security Warehouse represents the best in technology available thus providing more scope and performance from each of our solutions!
                        </p>
                        <p>
                            Security Warehouse is the owner of the Brand Natalus and has launched a range of NDAA compliant CCTV products for the International Market! 
                        </p>
                        <p>
                            Thank you for visiting us!
                        </p>
                    </Col>
                </Row>
                <Container>
                    <Row className={styles.rowMargins}>
                        <Col md={4}>
                            <Card style={{ width: '18rem' }}>
                                <Card.Img variant="top" src={process.env.PUBLIC_URL + "/Images/ProductImages/avigilon-h5a-camera-product-family.jpg"} className={styles.imageClass}/>
                                <Card.Body>
                                    <Card.Title>Avigilon</Card.Title>
                                    <a href="/avigilon">
                                        <Button variant="secondary">Learn More</Button>
                                    </a>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card style={{ width: '18rem' }}>
                                <Card.Img variant="top" src={process.env.PUBLIC_URL + "/Images/ProductImages/Qx.png"} className={styles.imageClass} />
                                <Card.Body>
                                    <Card.Title>Qumulex</Card.Title>
                                    <a href="/qumulex">
                                        <Button variant="secondary">Learn More</Button>
                                    </a>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card style={{ width: '18rem' }}>
                                <Card.Img variant="top" src={process.env.PUBLIC_URL + "/Images/ProductImages/raytec_ir.jpg"} className={styles.imageClass}/>
                                <Card.Body>
                                    <Card.Title>Raytec</Card.Title>
                                    <a href="/raytec">
                                        <Button variant="secondary">Learn More</Button>
                                    </a>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className={styles.rowMargins}>
                        <Col md={4}>
                            <Card style={{ width: '18rem' }}>
                                <Card.Img variant="top" src={process.env.PUBLIC_URL + "/Images/ProductImages/ACT_Access.jpg"} className={styles.imageClass}/>
                                <Card.Body>
                                    <Card.Title>Vanderbilt-Comnet</Card.Title>
                                    <a href="/vanderbilt">
                                        <Button variant="secondary">Learn More</Button>
                                    </a>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card style={{ width: '18rem' }}>
                                <Card.Img variant="top" src={process.env.PUBLIC_URL + "/Images/ProductImages/Natalus_ISI.png"} className={styles.imageClass}/>
                                <Card.Body>
                                    <Card.Title>Natalus</Card.Title>
                                    <a href="/natalus">
                                        <Button variant="secondary">Learn More</Button>
                                    </a>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card style={{ width: '18rem' }}>
                                <Card.Img variant="top" src={process.env.PUBLIC_URL + "/Images/ProductImages/Eyelock.jpg"} className={styles.imageClass}/>
                                <Card.Body>
                                    <Card.Title>Eyelock</Card.Title>
                                    <a href="/eyelock">
                                        <Button variant="secondary">Learn More</Button>
                                    </a>
                                </Card.Body>
                            </Card>
                        </Col>
                        
                    </Row>
                    <Row className={styles.rowMargins}>
                        <Col md={4}>
                            <Card style={{ width: '18rem' }}>
                                <Card.Img variant="top" src={process.env.PUBLIC_URL + "/Images/Logos/logo1.png"} className={styles.imageClass2}/>
                                <Card.Body>
                                    <Card.Title>Secure Logiq</Card.Title>
                                    <a href="/securelogiq">
                                        <Button variant="secondary">Learn More</Button>
                                    </a>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card style={{ width: '18rem' }}>
                                <Card.Img variant="top" src={process.env.PUBLIC_URL + "/Images/Logos/Alcatraz_AI_Logo_V_2_Colors.png"} className={styles.imageClass2}/>
                                <Card.Body>
                                    <Card.Title>Alcatraz AI</Card.Title>
                                    <a href="/alcatrazai">
                                        <Button variant="secondary">Learn More</Button>
                                    </a>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card style={{ width: '18rem' }}>
                                <Card.Img variant="top" src={process.env.PUBLIC_URL + "/Images/Logos/Sinpro.png"} className={styles.imageClass2}/>
                                <Card.Body>
                                    <Card.Title>SINPRO</Card.Title>
                                    <a href="/sinpro">
                                        <Button variant="secondary">Learn More</Button>
                                    </a>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    );
}

export default homePage;