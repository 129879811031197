import React from "react";
import "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel';
import styles from './MainCarousel.module.css';

const mainCarousel = () => {
    return (
        <div class={styles.carouselBackground}>
            <Carousel className={styles.MainCarousel_Margins + " d-none d-lg-block"}>
                <Carousel.Item interval={5000}>
                    <img
                        className="d-block w-100 Image_Size"
                        src={process.env.PUBLIC_URL + "/Images/Carousels/banner-3.jpg"}
                        alt="First slide"
                    />
                </Carousel.Item>
                <Carousel.Item interval={5000}>
                    <img
                        className="d-block w-100"
                        src={process.env.PUBLIC_URL + "/Images/Carousels/banner-4.jpg"}
                        alt="Second slide"
                    />
                </Carousel.Item>
                <Carousel.Item interval={5000}>
                    <img
                        className="d-block w-100"
                        src={process.env.PUBLIC_URL + "/Images/Carousels/banner-5.jpg"}
                        alt="Third slide"
                    />
                </Carousel.Item>
            </Carousel>
        </div>
    );
}

export default mainCarousel;