import React, {Fragment} from 'react';
import "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from 'react-bootstrap';
import styles from "./ContactUs.module.css";

const contactUs = () => {
    return (
        <>
            <Container>
                <Row className={styles.rowContent}>
                    <Col>
                        <p>
                            We are at your service for all your requirements! Please contact us at <a href="mailto:sales@securitywarehouse.co.in">sales@securitywarehouse.co.in</a>
                        </p>    
                    </Col>
                </Row>
                <Row className={styles.rowContent}>
                    <Col>
                        <h3>
                            Follow us on LinkedIn!
                        </h3>
                        <p><a href="https://www.linkedin.com/company/security-warehouse-india-pvt-ltd/" style={{ color: "#0A66C2" }}><i className="bi bi-linkedin" style={{ fontSize: 50 }}></i></a></p>
                    </Col>
                    
                </Row>
                <Row className={styles.rowContent}>
                    <Col>
                        <h3>
                            India Office:
                        </h3>
                        <p>Security Warehouse India Pvt. Ltd.<br/> C63/4, Okhla Industrial Area, Phase-II,<br/> New Delhi-110020 (INDIA)<br/></p>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default contactUs;