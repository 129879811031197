import React, {Fragment} from 'react';
import "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from 'react-bootstrap';
import styles from "./VanderbiltComnet.module.css";

const vanderbiltComnet = () => {
    return (
        <>
            <Container>
                <Row className={styles.rowContent}>
                    <Col>
                        <p>Security Warehouse is the Oldest Partner of Comnet in India.</p>
                        <p>Security Warehouse represents presently Vanderbilt – Comnet for their entire range of products.</p>
                        <p>Comnet  excels in transmission products and switching equipment for Industrial Grade Applications </p>
                        <p>Security Warehouse provides the ACT Access Control solution from Vanderbilt.</p>
                        <p>Be it corporates or banks or industries, Security Warehouse provides the complete range of IP to the
                            Door Controllers with OSDP Readers providing tremendous value and benefits in terms of cost and unbeatable technology ready for the future. 
                        </p>
                        <p>ACT is integrated with various Video Management Systems and ANPR Cameras and Elevator Companies.</p>
                        <p>Enterprise system provide for upto 4000 doors in a single enterprise system.</p>
                        <p>If you need to know more about Vanderbilt - Comnet contact <a href="mailto:sales@securitywarehouse.co.in">sales@securitywarehouse.co.in</a></p>
                        <p>Vanderbilt - Comnet product details are available at <a href="https://vanderbiltindustries.com/">Vanderbilt Industries | Security Systems {"&"} Solutions</a></p>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default vanderbiltComnet;