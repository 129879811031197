import React, {Fragment} from 'react';
import "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from 'react-bootstrap';
import styles from "./Qumulex.module.css";

const qumulex = () => {
    return (
        <>
            <Container>
                <Row className={styles.rowContent}>
                    <Col>
                        <p>
                        Security Warehouse proudly presents Qumulex.
                        </p>
                        <p>
                        Qumulex is an Unified Cloud Video Surveillance &amp; Access Control, Mobile-First, Cyber-Secure, Modern &amp; Intuitive Solution based on a SaaS Model.
                        </p>
                        <p>
                        Qumulex can be deployed in the cloud or on-premises to allow users to adopt new technology at their own individual pace. QxControl is open platform, enabling the use of cameras, door controllers, and analytics from the leading vendors to expand the breadth of solutions and intelligence available to users.
                        </p>
                        <p>
                        QxControl combines door control and video from many doors and cameras across a hierarchy of Places to deliver situational awareness. QxControl is designed and delivered mobile-first.
                        </p>
                        <p>
                        Qumulex is Open Platform with many features including strong cyber security norms, powerful search functions and unified access control to provide a complete solution.
                        </p>
                        <p>
                        Qumulex is a fully unified Video Management and Access Control System that is Browser-based, using a mobile-first, modern Progressive Web App that runs on any device with a browser. 
                        </p>
                        <p>
                        The Qumulex hybrid model allows for flexible deployment to meet customer needs.
                        </p>
                        <ul className={styles.ulMargins}>
                            <li>
                            All On-Premises
                            </li>
                            <li>
                            Mix of On-Premises &amp; Cloud
                            </li>
                            <li>
                            All Cloud
                            </li>
                            <li>
                            Facilitate transition by mix &amp; match, all with a common user experience.
                            </li>
                            <li>
                            Qumulex software is delivered as a Progressive Web App (PWA)
                            </li>
                            <li>
                            Single Application deployed via browser on any device (Windows, Linux, MacOS, Android, iOS)
                            </li>
                            <li>
                            Residential Ease-of-Use with Commercial Cybersecurity
                            </li>
                            <li>
                            Native HTML5, no browser plugins required	
                            </li>
                            <li>
                            Reduces security risks and eases administrative burden   
                            </li>
                        </ul>
                        <p>
                        Easily transition On-Premises installations to Recurring Monthly Subscriptions (RMS)
                        </p>
                        <p>
                        Open Platform assures maximum flexibility for security integrators to choose best of breed cameras, door controllers, switches, analytics, and cloud integrations.
                        </p>
                        <p>
                        To know more about Qumulex contact <a href="mailto:sales@securitywarehouse.co.in">sales@securitywarehouse.co.in</a>
                        </p>
                        <p>
                        Qumulex product information can be seen at <a href="https://www.qumulex.com/">Qumulex</a>
                        </p>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default qumulex;