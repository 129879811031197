import React, {Fragment} from 'react';
import "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from 'react-bootstrap';
import styles from "./Natalus.module.css";

const natalus = () => {
    return (
        <>
            <Container>
                <Row className={styles.rowContent}>
                    <Col>
                        <p>Security Warehouse proudly presents Natalus. A product developed and  backed by people who have a cumulative experience of over 100 years in CCTV.</p>
                        <p>Natalus is a brand fully owned by Security Warehouse India Pvt Ltd.</p>
                        <p>Natalus provides a complete range of CCTV products designed for video performance and reliability.</p>
                        <p>Natalus Cameras use Sony Starvis and Exmor Sensors for incredible picture quality comparable to very best in the Industry!</p>
                        <p>Natalus provides CCTV and CCTV accessories including transmission equipment to provide value and long term performance.</p>
                        <p>The Natalus Mission Statement  “We want CCTV to benefit people who eventually use it, so we must take care,
                            apply logic &amp; think on how it’s made, built, long term migration plus how it will be used by the people who buy it!’’ 
                            is the driving force behind each Natalus Product!
                        </p>
                        <p>The range of Natalus Products include Cameras in Dome, Bullet or Box formats in 2, 5, 8 &amp; 12MP formats. Natalus also provides  a range of IR &amp; Non-IR PTZ. 
                        </p>
                        <p>The Natalus NVR features high performance recording supporting H.265 compression to give outstanding recorded picture quality.</p>
                        <p>All Natalus products are fully NDAA compliant!</p>
                        <p>To know more about Natalus contact <a href="mailto:sales@securitywarehouse.co.in">sales@securitywarehouse.co.in</a></p>
                        <p>Natalus products information can be seen at <a href="http://www.natalus.in/">Natalus</a></p>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default natalus;