import React, {Fragment} from 'react';
import "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from 'react-bootstrap';
import styles from "./Raytec.module.css";

const raytec = () => {
    return (
        <>
            <Container>
                <Row className={styles.rowContent}>
                    <Col>
                        <p>Security Warehouse is one of the Oldest Partners of Raytec in India.</p>
                        <p>Security Warehouse has been associated with Raytec IR illuminators  with many safe city and smart city projects across India.</p>
                        <p>Raytec illuminators are designed and manufactured out of UK.</p>
                        <p>From Infra-Red, White-Light and Hybrid, to award winning IP lighting, explosion proof and ANPR lighting, 
                            Security Warehouse partners Raytec and offers the most comprehensive range of illuminators for any video surveillance application.
                        </p>
                        <p>
                        Achieve outstanding full scene images at night with enhanced even illumination. 
                        Raytec's latest illuminators feature Hot-spot Reduction Technology (HRT) and holographic lensing to deliver the highest quality images.
                        </p>
                        <p>
                        Lighting functionality, configuration and performance reach a new benchmark with the VARIO2 IP series of network illuminators. 
                        Fully IP addressable, VARIO2 IP enables you to create dynamic lighting systems, delivering the exact amount of light where and when it is needed. 
                        VARIO2 IP also integrates with 3rd party technology platforms and network devices.
                        </p>
                        <p>If you need to know more about Raytec range of illuminators contact <a href="mailto:sales@securitywarehouse.co.in">sales@securitywarehouse.co.in</a></p>
                        <p>Raytec products details can also be seen at <a href="https://www.rayteccctv.com/">Raytec</a></p>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default raytec;