import Navbar from "./UI/Navbar/Navbar";
import MainCarousel from "./UI/Carousel/MainCarousel/MainCarousel";
import HomePage from "./components/Pages/HomePage/HomePage";
import AboutUs from "./components/Pages/AboutUs/AboutUs";
import OurCustomers from "./components/Pages/OurCustomers/OurCustomers";
import Avigilon from "./components/Pages/Avigilon/Avigilon";
import Natalus from "./components/Pages/Natalus/Natalus";
import Qumulex from "./components/Pages/Qumulex/Qumulex";
import Raytec from "./components/Pages/Raytec/Raytec";
import Vanderbilt from "./components/Pages/VanderbiltComnet/VanderbiltComnet";
import Eyelock from "./components/Pages/Eyelock/Eyelock";
import SecureLogiq from "./components/Pages/SecureLogiq/Securelogiq";
import AlcatrazAI from "./components/Pages/AlcatrazAI/Alcatraz"; 
import Sinpro from "./components/Pages/Sinpro/Sinpro";
import Services from "./components/Pages/OurServices/OurServices";
import ContactUs from "./components/Pages/ContactUs/ContactUs";
import News from "./components/Pages/News/News";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Switch } from "react-router-dom";

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar/>
        <MainCarousel/>
        <Switch>
          <Route path="/aboutUs" component={AboutUs}/>
          <Route path="/ourCustomers" component={OurCustomers}/>
          <Route path="/news" component={News}/>
          <Route path="/avigilon" component={Avigilon}/>
          <Route path="/natalus" component={Natalus}/>
          <Route path="/qumulex" component={Qumulex}/>
          <Route path="/raytec" component={Raytec}/>
          <Route path="/vanderbilt" component={Vanderbilt}/>
          <Route path="/eyelock" component={Eyelock}/>
          <Route path="/securelogiq" component={SecureLogiq}/>
          <Route path="/alcatrazai" component={AlcatrazAI}/>
          <Route path="/sinpro" component={Sinpro}/>
          <Route path="/services" component={Services}/>
          <Route path="/contactUs" component={ContactUs}/>
          <Route exact path="/" component={HomePage}/> 
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
