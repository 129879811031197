import React, {Fragment} from 'react';
import "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from 'react-bootstrap';
import styles from "./News.module.css";

const ourCustomers = () => {
    return (
        <>
            <Container>
                <Row className={styles.rowContent}>
                    <Col>
                        <h3>February 2024</h3>
                        <p>Security Warehouse is happy to announce a strategic alliance with Sinpro for a long-term
                            operational strategy, and reinforces our commitment to embracing state-of-the-art
                            technologies. Security Warehouse has been pivotal in bringing technology to make our society
                            and world a safer place.
                        </p>
                        <p>
                            It is with great enthusiasm that we announce our collaboration with <a href="https://sinpro.si/">SINPRO </a>
                             as our new partner for India, Bangladesh, Sri Lanka, Nepal and Bhutan.
                        </p>
                        <p>
                            Security Warehouse brings to the table an extensive and profound expertise in providing
                            solutions tailored for the security sector. Under this agreement Security Warehouse will sell
                            SINPRO products which include VMS, Video Intelligence based Analytics, PSIM and Asset
                            Tracking Products through our existing partner channels and will look for new partners to
                            engage to grow the business. Furthermore, SINPRO will be our technology partner for the
                            Natalus brand of products. The Natalus range will now bear the logo “POWERED BY SINPRO”.
                            Our Solutions will now complement our Server Technology Partnerships as well.
                        </p>
                        <p>
                            Together, our collaboration is set to introduce Innovative, Reliable, Affordable, Intelligent and
                            cutting-edge security solutions that cater to the evolving needs of the market. We look forward
                            to a fruitful and dynamic partnership with SINPRO as we aim to elevate the standards of safety
                            and security across the regions.
                        </p>
                        <p>
                            SINPRO stands as an innovative producer of a diverse array of software and hardware solutions
                            tailored to meet the dynamic needs of today's technological landscape. Their extensive suite
                            includes advanced video analytics, comprehensive video management systems, sophisticated
                            event and data management platforms, precise asset tracking, and a variety of custom
                            solutions designed to cater to specific client requirements.
                        </p>
                        <p>
                            SINPRO, is dedicated to leveraging the power of technology to deliver integrated and intelligent
                            systems that empower businesses to monitor, analyze, and manage their operations more
                            efficiently. Whether for small-scale enterprises or large-scale industrial applications, SINPRO's
                            bespoke offerings are designed to deliver excellence and drive the future of digital integration.
                        </p>
                        <p>
                            To Learn more about SINPRO please visit their website <a href="https://sinpro.si/">SINPRO</a> or email to <a href="emailto:contact@sinpro.si">contact@sinpro.si</a>
                        </p>
                        <p>
                        To get in touch with Security Warehouse please send us a mail at <a href="emailto:connect@securitywarehouse.co.in">connect@securitywarehouse.co.in</a>
                        </p>
                    </Col>
                </Row>
                <Row className={styles.rowContent}>
                    <Col>
                        <h3>July 2021</h3>
                        <p>Security Warehouse announces its tie-up with Qumulex. Qumulex is a fully unified Video Management and Access Control System 
                            that is Browser-based, using a mobile-first, modern Progressive Web App that runs on any device with a browser. 
                            The hybrid model allows for flexible deployment to meet customer needs, whether that be all cloud, all on-premises, or a mixture.
                            Open Platform assures maximum flexibility for security integrators to choose best of breed cameras, door controllers, switches, 
                            analytics, and cloud integrations. With this tie-up Security Warehouse brings to India a proven value for money Cloud Surveillance solution to India.
                        </p>
                    </Col>
                </Row>
                <Row className={styles.rowContent}>
                    <Col>
                        <h3>Nov. 2020</h3>
                        <p>Security Warehouse announces the launch of its own Brand of CCTV Cameras and Network Recorders! The Brand is called Natalus. 
                            The Natalus Brand of cameras provide exceptional low light performance and are available in Dome, Box or Bullet formats with an outstanding IR PTZ range. 
                            The Entire range of Natalus Products are NDAA compliant and cater to International Quality Certifications and are BIS certified. 
                            The entire range include special cameras for the ITS segment and will set a benchmark for performance for low light applications.
                        </p>
                    </Col>
                </Row>
                <Row className={styles.rowContent}>
                    <Col>
                        <h3>Oct. 2020</h3>
                        <p>
                            Avigilon announced its 10K / 61MP H5 Pro camera, more than doubling its previous highest resolution camera. 
                            Avigilon H5 Camera platform is introducing three new cameras, including the H5 Pro, the H5A-Pan-Tilt-Zoom (PTZ) and the H5A-Corner Camera. 
                            Each of the three cameras is embedded with next-generation video analytics for enhanced objection detection, tracking and classification, 
                            Unusual Activity Detection (UAD), a number of <a href="https://www.avigilon.com/solutions/covid-19-resources">pandemic response</a> technologies 
                            and support for FIPS 140-2 certified cryptography and <a href="https://www.avigilon.com/products/ai-video-analytics/appearance-search">Avigilon Appearance Search™</a> 
                            technology to proactively flag critical events for quicker, decisive responses.  
                        </p>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ourCustomers;