import React from "react";
import { Container } from "react-bootstrap";
import styles from "./Alcatraz.module.css";
import "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const alcatrazPage = () => {
    return (
        <>
            <Container>
                <Row className={styles.rowContent2}>
                    <Col md={4}>
                        <img src={process.env.PUBLIC_URL + "/Images/ProductImages/image.png"} className={styles.imageClass2}/>
                    </Col>
                    <Col md={4}>
                        <img src={process.env.PUBLIC_URL + "/Images/ProductImages/image2.png"} className={styles.imageClass2}/>
                    </Col>
                    <Col md={4}>
                        <img src={process.env.PUBLIC_URL + "/Images/ProductImages/image1.png"} className={styles.imageClass2}/>
                    </Col>
                </Row>
                <Row className={styles.rowContent}>
                    <Col>
                        <p>
                            Security Warehouse and Alcatraz AI Form Strategic Partnership to Bring AI-Powered Security Solutions to India.   
                        </p>
                        <p>
                            Alcatraz AI manufactures the award-winning autonomous access control solution, the Rock. 
                        </p>
                        <p>
                            The Rock is the first facial authentication technology that leverages artificial intelligence and analytics to make powerful decisions 
                            at the edge, where your face becomes your credential.
                        </p>
                        <p>
                            Designed and built in the United States, the Rock instantly detects unauthorized access giving you complete security at every door, making spaces safer. 
                            Powered by machine learning technology, the Rock ensures real-time authentication, analytics and access control.
                        </p>
                        <p>
                            The Rock can work with any Third-Party Access Control System and is both Wiegand and OSDP capable.
                        </p>
                        <p>
                            Powerful features and capabilities, including: 
                        </p>
                        <ul>
                            <li>
                            	Frictionless Access - Create fewer touchpoints when entering a controlled space by using 3D facial authentication. 
                            </li>
                            <li>
                            	Multi-factor Authentication - Combining two or more authentication factors adds a layer of protection to a restricted area in a zero-trust environment.
                            </li>
                            <li>
                            	Tailgating Detection - Designed to alert if an unauthorized person enters and sends real-time notifications to the access control &amp; video management system.
                            </li>
                            <li>
                            	Video at the Door - The Alcatraz Rock can function as an ONVIF camera. The video stream can be added to any video management system that supports ONVIF, giving security teams a unique perspective of who is accessing an area.
                            </li>
                        </ul>
                        <p>
                            To know more about The Rock contact <a href="mailto:sales@securitywarehouse.co.in">sales@securitywarehouse.co.in</a>.
                        </p>
                        <p>
                            More information on Alcatraz AI can be found  <a href="https://www.alcatraz.ai/">here</a>.
                        </p>
                    </Col>
                </Row>
                <Row className={styles.rowContent}>
                    <Col md={4}>
                        <img src={process.env.PUBLIC_URL + "/Images/ProductImages/Alcatraz_AI_3FA.jpg"} className={styles.imageClass}/>
                    </Col>
                    <Col md={4}>
                        <img src={process.env.PUBLIC_URL + "/Images/ProductImages/Alcatraz_AI_Server_Room.jpg"} className={styles.imageClass}/>
                    </Col>
                    <Col md={4}>
                        <img src={process.env.PUBLIC_URL + "/Images/ProductImages/Alcatraz_AI_Data_Center.jpg"} className={styles.imageClass}/>
                    </Col>
                </Row>
            </Container>
        </>
    );

}

export default alcatrazPage;