import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import styles from './Sinpro.module.css'

function Sinpro() {
  return (
    <>
        <Container>
            <Row className={styles.rowContent2}>
                <Col>
                    <p>
                        Security Warehouse and SINPRO form a Strategic Partnership to Introduce AI-Powered Security Solutions to India.
                    </p>
                    <p>
                        SINPRO is a leading developer and manufacturer of sophisticated software and hardware solutions for physical security applications. 
                        Their mission is to deliver a systemic approach to security and digitalization, enabling businesses to safeguard against both internal and external threats.
                    </p>
                    <p>
                        SINPRO offers an extensive portfolio of products for System Integration and Incident Control, Video Management, Facial Recognition, License Plate Recognition, 
                        Video Analytics, and tailor-made solutions for unique requirements.
                    </p>
                    <p>
                        SINPRO stands out as a provider of state-of-the-art solutions for integrated security!
                    </p>
                    <p>
                        For more information about SINPRO or to discover how their solutions can protect and enhance your business operations, 
                        please reach out to us at <a href="mailto:connect@securitywarehouse.co.in">connect@securitywarehouse.co.in</a>.
                    </p>
                    <p>
                        Visit SINPRO website at <a href="https://sinpro.si/">SINPRO</a> to learn more about their innovative offerings  or email to <a href="emailto:connect@securitywarehouse.co.in">connect@securitywarehouse.co.in</a>
                    </p>
                </Col>
            </Row>
        </Container>
    </>
  )
}

export default Sinpro