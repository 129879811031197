import React, {Fragment} from 'react';
import "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from 'react-bootstrap';
import styles from "./Eyelock.module.css";

const eyelock = () => {
    return (
        <>
            <Container>
                <Row className={styles.rowContent}>
                    <Col>
                        <p>
                        Security Warehouse presents Eyelock, market leader of iris-based identity authentication solutions.  
                        </p>
                        <p>
                            Iris authentication is highly secure because no two irises are alike and the iris is the most accurate human 
                            identifier other than DNA. EyeLock LLC is an acknowledged leader in advanced iris authentication providing 
                            the highest level of security with EyeLock ID. EyeLock's technology provides an unprecedented level of convenience 
                            and security with unmatched biometric accuracy, making it the most proven way to authenticate identity aside from DNA. 
                            EyeLock's proprietary iris authentication technology looks at more than 240 unique iris characteristics and provides a fast,
                            user-friendly authentication experience. EyeLock iris authentication is suitable into other technologies and is suitable for
                            use in many market segments. While biometric modalities such as facial and fingerprint recognition are satisfactory for low
                            to medium security applications, iris provides the highest level of security and convenience.Notable use cases for iris 
                            identification exists today within enterprise, healthcare, financial services, education, corrections, stadiums, border control, 
                            automotive, government, and other prominent business applications. EyeLock is committed to continuously increasing the strength 
                            of its patent portfolio with over 75 Patents!
                        </p>
                    </Col>
                </Row>
                <Row className={styles.rowContent}>
                    <Col>
                        <h3 className={styles.headingPadding}>How Eyelock Works</h3>
                        <p>
                            The EyeLock process is both simple and secure. First, the device takes high resolution pictures of the user. Then, the EyeLock algorithm 
                            detects the pattern of the colored portion of the iris images to extract specific markers, also known as "template". The user's template 
                            is then compared to an existing database of templates looking for an identity match. Once a match is found, the device communicates the identity 
                            of the user to the Access Control System. The entire process takes a fraction of a second. Unlike many other biometric and card-based control solutions, 
                            nano EXT™ identifies people in real time and at a distance, in indoor and outdoor locations. nano EXT™ allows processing people through doors or checkpoints 
                            at a rate of up to 20 people per minute without delays or backups. The device is designed to operate in direct sunlight, snow or rain and temperatures 
                            between -40°C to 66°C (-40°F to 150.8°F). The device's sleek and unobtrusive design is also vandal-resistant. nano EXT™ features automatic height adjustment 
                            to allow simple interaction regardless of the user height. With no cards, fobs or keys to replace or upgrade, and no risk of loss or theft, iris technology 
                            makes credential management incredibly easy and cost-effective.
                        </p>
                    </Col>
                </Row>
                <Row className={styles.rowContent}>
                    <Col>
                        <h3>Advantages</h3>
                        <ul className={styles.ulMargins}>
                            <li>
                            Secure - 1 in 2.25 trillion FAR
                            </li>
                            <li>
                            3rd party Novetta testing proves FAR = 1:1,500,000 per eye
                            </li>
                            <li>
                            Superior algorithms = longer working distance from shorter lenses
                            </li>
                            <li>
                            HYGIENIC - EyeLock is a fully touchless system, authentication can be completed without the requirement to remove eyewear, face masks and gloves.
                            </li>
                            <li>
                            System-level expertise: algorithm, optical, software, &amp; hardware
                            </li>
                            <li>
                            Pulsed illumination reduces power and increases safety
                            </li>
                            <li>
                            Complete range of freedom for matching (1:1, 1:n, local, network, cloud, multi-factor)
                            </li>
                        </ul>
                        <p>
                        To know more about Eyelock contact <a href="mailto:sales@securitywarehouse.co.in">sales@securitywarehouse.co.in</a>
                        </p>
                        <p>
                        Eyelock product information can be seen at <a href="https://www.eyelock.com/">Eyelock</a>
                        </p>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default eyelock;